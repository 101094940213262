<template lang="">
  <div>
    <v-expansion-panel-header>Información personal</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>person</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ usersStore.userSelectedData.get_person.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>fingerprint</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ usersStore.userSelectedData.get_person.identifier }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>call</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ usersStore.userSelectedData.get_person.phone }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>admin_panel_settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{
              usersStore.userSelectedData.get_person.get_type_person.description
            }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>email</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ usersStore.userSelectedData.email }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-content>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ProfilePersonalData",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.users,
    }),
  },
};
</script>

export const gender = [
    {
        value: 1,
        text: "Masculino"
    },
    {
        value: 2,
        text: "Femenino"
    },
    {
        value: 3,
        text: "Otro"
    },

]
<template lang="">
  <div>
    <v-expansion-panel-header>
       <span class="fvl-title-form">
          Información Personal
        </span>
      </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-5">
      <form
        @submit.prevent="sendFormResetPassProfile"
        id="formProfileResetPass"
      >
        <v-col cols="12" md="6">
        <v-text-field
          v-model="$v.oldPass.$model"
          outlined
          label="Contraseña anterior"
          :error-messages="oldPasswordErrors"
          :type="showOldPass ? 'text' : 'password'"
          :append-icon="showOldPass ? 'visibility' : 'visibility_off'"
          @click:append="showOldPass = !showOldPass"
          required
          @blur="$v.oldPass.$touch()"
        ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
        <v-text-field
          v-model="$v.newPass.$model"
          :error-messages="newPasswordErrors"
          outlined
          :append-icon="showNewPass ? 'visibility' : 'visibility_off'"
          name="newPass"
          label="Nueva contraseña"
          :type="showNewPass ? 'text' : 'password'"
          hint="Al menos 6 caracteres"
          @click:append="showNewPass = !showNewPass"
          @blur="$v.newPass.$touch()"
        ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
        <v-text-field
          v-model="$v.passwordRepeat.$model"
          :error-messages="passwordRepeatErrors"
          outlined
          :append-icon="showNewRepeatPass ? 'visibility' : 'visibility_off'"
          name="password"
          label="Repita su nueva contraseña"
          :type="showNewRepeatPass ? 'text' : 'password'"
          hint="Al menos 6 caracteres"
          @click:append="showNewRepeatPass = !showNewRepeatPass"
          @blur="$v.passwordRepeat.$touch()"
        ></v-text-field>
        </v-col>

        <Button
          :disabled="$v.$invalid"
          classes="mr-4 float-right fvl-btn green-primary"
          type="submit"
          name="Guardar"
        />
      </form>
    </v-expansion-panel-content>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Button from "../_commons/Button.vue";
import { beginRequest, endRequest, links } from "../../helpers";
import router from "../../router";
export default {
  name: "ProfileResetPass",
  components: {
    Button,
  },
  mixins: [validationMixin],
  validations: {
    oldPass: { required },
    newPass: { required, minLength: minLength(6) },
    passwordRepeat: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs("newPass"),
    },
  },
  data() {
    return {
      oldPass: "",
      showOldPass: false,
      newPass: "",
      showNewPass: false,
      passwordRepeat: "",
      showNewRepeatPass: false,
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.users,
    }),
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPass.$dirty) return errors;
      !this.$v.oldPass.required &&
        errors.push("La contraseña anterior es requerida");
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPass.$dirty) return errors;
      !this.$v.newPass.minLength &&
        errors.push("La longitud mínima es de 6 caracteres");
      !this.$v.newPass.required &&
        errors.push("La contraseña nueva es requerida");
      return errors;
    },
    passwordRepeatErrors() {
      const errors = [];
      if (!this.$v.passwordRepeat.$dirty) return errors;
      !this.$v.passwordRepeat.minLength &&
        errors.push("La longitud mínima es de 6 caracteres");
      !this.$v.passwordRepeat.required &&
        errors.push("Debe repetir su nueva contraseña");
      !this.$v.passwordRepeat.sameAsPassword &&
        errors.push("las constraseñas no coinciden");
      return errors;
    },
  },
  methods: {
    async sendFormResetPassProfile() {
      await beginRequest();
      const formData = {
        password: this.oldPass,
        new_password: this.newPass,
      };
      const payload = {
        id: this.usersStore.userSelectedData.id,
        data: formData,
      };
      const { status, snack } = await this.$store.dispatch(
        "resetPasswordProfile",
        payload
      );
      if (status >= 200 && status < 300) {
        if (sessionStorage.getItem("user")) {
          sessionStorage.removeItem("user");
        } else if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
        await this.$store.dispatch("setUserSession", null);
        // router.push(links.login.login); // SI EL USER NO ESTA AUTENTICADO, LO ENVIA A LA PAGE DEL LOGIN
      }
      await endRequest(snack, status);
    },
  },
};
</script>

<template lang="">
  <v-container class="">
    <BreadCrumbs :items="itemsBreadCrumbs" />
    <div v-if="usersStore && usersStore.userSelectedData">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel>
          <ProfilePersonalOwnData />
        </v-expansion-panel>
        <v-expansion-panel>
          <ProfilePermissions />
        </v-expansion-panel>

        <v-expansion-panel>
          <ProfileResetPass />
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <Overlay />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import { BreadCrumbs, Overlay } from "../../components/_commons";
import { mapState } from "vuex";
import ProfilePersonalData from "../../components/profile/ProfilePersonalData.vue";
import ProfilePersonalOwnData from "../../components/profile/ProfilePersonalOwnData.vue";
import ProfilePermissions from "../../components/profile/ProfilePermissions.vue";
import ProfileResetPass from "../../components/profile/ProfileResetPass.vue";
export default {
  name: "Profile",
  components: {
    BreadCrumbs,
    ProfilePersonalData,
    ProfilePersonalOwnData,
    ProfilePermissions,
    ProfileResetPass,
    Overlay,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.userProfile,
      panel: [0],
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.users,
      userSession: (state) => state.user,
    }),
  },
  async created() {
    await beginRequest();
    const { snack, status } = await this.$store.dispatch(
      "setUserSelectedDataAction",
      this.userSession.id_user
    );
    await endRequest(snack, status);
  },
};
</script>

<template lang="">
  <div>
    <v-expansion-panel-header  elevation="0">
      <span class="fvl-title-form">
          Permisos
       </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content  elevation="0">
      <v-list-item
        v-for="(item, index) in usersStore.userSelectedData.permissions"
        :key="index"
      >
        <v-list-item-icon>
          <v-icon>verified_user</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-content>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ProfilePermissions",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.users,
    }),
  },
};
</script>

<template lang="">
  <div>
      <v-expansion-panel-header>
        <span class="fvl-title-form">
          Información Personal
        </span>
      </v-expansion-panel-header>
    <form @submit.prevent="sendFormProfile" id="formProfilePersonalData">
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  outlined
                  class="pa-1" 
                  v-model="$v.name.$model"
                  label="Nombre (*)"
                  :error-messages="nameErrors"
                  @blur="$v.name.$touch()"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-autocomplete
                  v-model="genderSelected"
                  :items="genderItems"
                  outlined
                  class="pa-1" 
                  label="Sexo"
                ></v-autocomplete>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field outlined class="pa-1"  v-model="phone" label="Teléfono (*)">
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  outlined
                  class="pa-1" 
                  filled
                  :value="usersStore.userSelectedData.get_person.identifier"
                  label="Documento"
                  disabled
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  outlined
                  filled
                  class="pa-1" 
                  :value="
                    usersStore.userSelectedData.get_person.get_type_person
                      .description
                  "
                  label="Tipo de usuario"
                  disabled
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  outlined
                  filled
                  class="pa-1" 
                  :value="usersStore.userSelectedData.email"
                  label="Tipo de usuario"
                  disabled
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </v-col>
        </v-row>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <Button
                :disabled="$v.$invalid"
                classes="mr-4 float-right fvl-btn green-primary"
                type="submit"
                name="Guardar"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-content>
    </form>
    <SnackBar />
  </div>
    
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { gender } from "../../data/gender";
import { beginRequest, endRequest } from "../../helpers";
import { Button, SnackBar } from "../_commons";
export default {
  name: "ProfilePersonalOwnData",
  components: { Button, SnackBar },
  mixins: [validationMixin],
  validations: {
    name: { required, minLength: minLength(2) },
    genderSelected: { required },
  },
  data() {
    return {
      name: "",
      genderSelected: "",
      genderItems: gender,
      phone: "",
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.users,
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.minLength &&
        errors.push("La longitud minima es de 2 caracteres");
      !this.$v.name.required && errors.push("El nombre es requerido");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("El género es requerido");
      return errors;
    },
  },
  methods: {
    async sendFormProfile() {
      await beginRequest();
      const formData = {
        namePerson: this.name,
        phone: this.phone,
        gender: this.genderSelected,
      };
      const payload = {
        id: this.usersStore.userSelectedData.id,
        data: formData,
      };
      const { status, snack } = await this.$store.dispatch(
        "updateUserNoAdmin",
        payload
      );
      if (status >= 200 && status < 300) {
        let userStorage = "";
        let isSessionStorage = false;
        if (sessionStorage.getItem("user")) {
          userStorage = JSON.parse(sessionStorage.getItem("user"));
          isSessionStorage = true;
        } else if (localStorage.getItem("user")) {
          userStorage = JSON.parse(localStorage.getItem("user"));
          isSessionStorage = false;
        }
        userStorage.person.name = this.name;
        userStorage.person.gender = this.genderSelected;
        userStorage.person.phone = this.phone;
        if (isSessionStorage) {
          sessionStorage.setItem("user", JSON.stringify(userStorage));
        } else {
          localStorage.setItem("user", JSON.stringify(userStorage));
        }
        await this.$store.dispatch("setUserSession", userStorage);
      }
      await endRequest(snack, status);
    },
  },
  async created() {
    await beginRequest();
    this.name = this.usersStore.userSelectedData.get_person.name;
    this.genderSelected = this.usersStore.userSelectedData.get_person.gender;
    this.phone = this.usersStore.userSelectedData.get_person.phone;
    await endRequest(false, 200);
  },
  watch: {
    usersStore: {
      async handler() {
        await beginRequest();
        this.name = this.usersStore.userSelectedData.get_person.name;
        this.genderSelected = this.usersStore.userSelectedData.get_person.gender;
        this.phone = this.usersStore.userSelectedData.get_person.phone;
        await endRequest(false, 200);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
